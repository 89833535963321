import React, {FunctionComponent} from "react"
import AuthenticatedFullscreenLayout from "../components/layouts/authenticatedFullscreenLayout";
import NavbarInContext from "../components/navBarInContext";
import Step from "../components/course-browser/step";

const MainPage: FunctionComponent = () => {
    return (
        <AuthenticatedFullscreenLayout title="Seventeen Days">
            <Step/>
        </AuthenticatedFullscreenLayout>
    )
}

export default MainPage
